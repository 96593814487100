<template>
     <div class="home full-height">
          <v-container fill-height style="max-width:940px">
               <v-row class="full-height" align="center" justify="center">
                    <v-col v-if="successDownload">
                         <v-card class="text-center">
                             <v-card-text>
                                   <a :download="filename" :href="successDownload"  >
                                   <v-card class="mx-auto my-0" elevation="5" :max-width="(width) ? width : 540">
                                        <v-img refs="shareImg" :src="successDownload"></v-img>
                                   </v-card>
                                   </a>
                             </v-card-text>
                             <v-divider></v-divider>
                             <v-card-text>
                                   <p class="my-0"> {{$t('pages.camera.retry-download')}} </p>
                              </v-card-text>
                              <v-card-actions class="text-center">
                                   <a :href="successDownload" :download="filename"  class="v-btn v-btn--block"> 
                                    <v-btn color="success"  large block >{{$t('pages.camera.download-btn-text')}}</v-btn>
                                   </a>
                              </v-card-actions>
                              <v-card-actions>
                                   <v-btn color="info"  small outlined block :to="{name:'home'}">{{$t('pages.camera.back-btn-text')}}</v-btn>
                              </v-card-actions>
                        </v-card>
                    </v-col>
                    <v-col cols="12" v-if="errors">
                        <v-card class="text-center">
                             <v-card-text>
                                  <p>{{$t('pages.camera.error-browser')}}</p>

                                   <p class="my-0">
                                        {{errors}}
                                   </p>   
                              </v-card-text>
                              <v-card-actions>
                                   <v-btn color="info"  small outlined block :to="{name:'home'}">{{$t('pages.camera.back-btn-text')}}</v-btn>
                              </v-card-actions>
                        </v-card>
                    </v-col>
                    <v-col cols="12" v-if="!errors && !successDownload">
                         <v-card class="text-center" v-show="!isCameraOpen">
                              <v-card-text>
                                   <p class="my-0 small">
                                        {{$t('pages.camera.privacy')}}
                                   </p>   
                              </v-card-text>
                              <v-card-actions>
                                   <v-btn block color="primary" @click="openCamera">
                                        <span>{{$t('pages.camera.open-btn-text')}}</span>
                                   </v-btn>
                              </v-card-actions>
                              <v-card-actions>
                                   <v-btn block outlined color="info" :to="{name:'home'}">
                                        <span>{{$t('pages.camera.back-btn-text')}}</span>
                                   </v-btn>
                              </v-card-actions>
                              <v-card-text class="text-start">
                                   <v-btn x-small icon @click="copyText(browser)">
                                        <v-icon>mdi-browser</v-icon> 
                                   </v-btn>
                                   <small v-if="browserView">{{browser}}</small>
                              </v-card-text>
                         </v-card>
                         <v-card class="text-center" v-show="isCameraOpen" >
                              <v-card-text>
                                  <v-card class="mx-auto my-0" elevation="5" :max-width="(width) ? width : 540">
                                        <div 
                                             ref="camerabox"
                                             class="camera-box mx-auto"
                                             :class="(isFrontCamera) ? 'front-camera': 'back-camera'"
                                            v-show="!isPhotoTaken"
                                        >
                                        <vue-web-cam
                                             ref="webcam"
                                             :device-id="deviceId"
                                             :width="width"
                                             :height="width*ratio"
                                             @started="onStarted"
                                             @stopped="onStopped"
                                             @error="onError"
                                             @cameras="onCameras"
                                             @camera-change="onCameraChange"
                                        />
                                        <img 
                                             ref="mask" 
                                             :src="require('@/assets/mask/'+mask+'.png')" 
                                             class="mask"
                                             :style="'margin-top: -'+height+'px'"
                                        />
                                         <img v-show="img" :src="img" ref="capture" class="capture" />
                                       </div>
                                       <div 
                                             class="camera-box mx-auto"
                                             v-show="isPhotoTaken"
                                        >
                                         <canvas 
                                             ref="canvas" 
                                             :width="width"
                                             :height="width*ratio"
                                        ></canvas>
                                       
                                        </div>
                              
                                  </v-card>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions v-if="!isPhotoTaken">
                                   <v-btn block color="primary" @click="onCapture">
                                        <span>{{$t('pages.camera.take-btn-text')}}</span>
                                   </v-btn>
                              </v-card-actions>
                              <v-card-actions v-if="!isPhotoTaken && devices.length > 1">
                                  <v-select 
                                        outlined
                                        dense
                                        :label="$t('attributes.devices.label')"
                                        @input="cameraChange"
                                        v-model="camera"
                                        :items="devices"
                                        item-text="label"
                                        item-value="deviceId"
                                        clearable
                                        class="text-center"
                                   >
                                   </v-select>
                              </v-card-actions>
                              <v-card-text v-if="isPhotoTaken">
                                   <p class="small my-0"> {{$t('pages.camera.privacy_download')}}</p>
                              </v-card-text>
                              <v-card-actions v-if="isPhotoTaken">
                                   <v-btn :loading="loading" block color="primary" @click="downloadImage" >
                                        <span class="light--text">{{$t('pages.camera.download-btn-text')}}</span>
                                   </v-btn>
                              </v-card-actions>
                              <v-card-actions v-if="isPhotoTaken">
                                   <v-btn block color="secondary" @click="retry">
                                        <span class="black--text">{{$t('pages.camera.retry-btn-text')}}</span>
                                   </v-btn>
                              </v-card-actions>
                              <v-card-actions>
                                   <v-btn block outlined color="info" :to="{name:'home'}">
                                        <span>{{$t('pages.camera.back-btn-text')}}</span>
                                   </v-btn>
                              </v-card-actions>
                              <v-card-text class="text-start">
                                   <v-btn x-small icon @click="copyText(browser)">
                                        <v-icon>mdi-browser</v-icon>
                                   </v-btn>
                              </v-card-text>
                         </v-card>
                    </v-col>
               </v-row>
          </v-container>
     </div>
</template>

<script>
import { WebCam } from "vue-web-cam";  
import axios from "axios"
import moment from "moment"
import loadImage from "blueimp-load-image";

const browsersErrImg = [
     "Mozilla/5.0 (iPhone; CPU iPhone OS 14_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.0 Mobile/15E148 Safari/604.1",
     "Mozilla/5.0 (iPhone; CPU iPhone OS 15_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.0 Mobile/15E148 Safari/604.1",
     "Mozilla/5.0 (iPhone; CPU iPhone OS 15_0_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.0 Mobile/15E148 Safari/604.1",
     "Mozilla/5.0 (iPhone; CPU iPhone OS 15_0_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.0 Mobile/15E148 Safari/604.1",
     "Mozilla/5.0 (iPhone; CPU iPhone OS 15_0_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.0 Mobile/15E148 Safari/604.1",
     "Mozilla/5.0 (iPhone; CPU iPhone OS 15_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/96.0.4664.53 Mobile/15E148 Safari/604.1",
     "Mozilla/5.0 (iPhone; CPU iPhone OS 15_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/96.0.4664.53 Mobile/15E148 Safari/604.1",
     "Mozilla/5.0 (iPhone; CPU iPhone OS 15_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/96.0.4664.101 Mobile/15E148 Safari/604.1",
     "Mozilla/5.0 (iPhone; CPU iPhone OS 15_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.1 Mobile/15E148 Safari/604.1",
     "Mozilla/5.0 (Android 10; Mobile; rv:95.0) Gecko/95.0 Firefox/95.0",
     "Mozilla/5.0 (Linux; Android 10; Mi 9 Lite) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.166 Mobile Safari/537.36 OPR/65.2.3381.61420",
     "Mozilla/5.0 (Linux; Android 10; SM-A105FN) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/80.0.3987.99 Mobile Safari/537.36",
];

const browserDownErr = [
     "Mozilla/5.0 (iPhone; CPU iPhone OS 15_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.1 Mobile/15E148 Safari/604.1",
     "Mozilla/5.0 (iPhone; CPU iPhone OS 15_0_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.0 Mobile/15E148 Safari/604.1",
     "Mozilla/5.0 (iPhone; CPU iPhone OS 15_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/96.0.4664.53 Mobile/15E148 Safari/604.1",
];


export default {
     name: "Camera",
     components: {
        "vue-web-cam": WebCam
     },
     data: () => ({
          successDownload: null,
         isCameraOpen: false,
         isPhotoTaken: false,
         deviceId: null,
         devices: [],
         img: null,
         browserView: false,
         imgKey: 'null',
         camera: null,
         filename: 'pic.jpg',
         isFrontCamera: true,
         width: null,
         height: null,
         streamCameraWidth: null,
         streamCameraHeight: null,
         streamCameraRatio: null,
         positionH: null,
         ratio: 1.48148148148,
         errors: null,
         loading: null,
     }),
     computed: {
          mask: function() {
            return this.$route.params.mask
          },
          isMobile: function(){
               if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return true
               } else {
                    return false
               }
          },
          isMobileIos: function(){
               console.log('browser',this.$browserDetect)
               return this.$browserDetect.isIOS
          },
          browserHasErrImg: function(){
               return browsersErrImg.includes(navigator.userAgent)
          },
          errDownload: function(){
               return browserDownErr.includes(navigator.userAgent)
          },
          browser: function(){
               return navigator.userAgent;
          },
          canShare: function(){return navigator.canShare},
          device: function() {
            return this.devices.find(n => n.deviceId === this.deviceId);
          }
     },
     watch: {
        camera: function(id) {
            this.deviceId = id;
        },
        devices: function() {
            // Once we have a list select the first one
            const [first, ...tail] = this.devices;
               console.log('other devices',tail)
            if (first) {
                this.camera = first.deviceId;
                this.deviceId = first.deviceId;
               console.log('CAMERA LABEL',first.label)
                this.isFrontCamera = (first.label.includes("front") || first.label.includes("Front") || first.label.includes("anteriore") || first.label.includes('integrata') );
                console.log('this.isFrontCamera',this.isFrontCamera)
            }
            if(tail.length == 0){
                 this.isFrontCamera = true
            }
        },
     },
     beforeDestroy(){
          
     },
     methods:{
          cameraChange(e){
              var select =  this.devices.find(n => n.deviceId === e);
               console.log('cameraChange',select)
               this.isFrontCamera = (select.label.includes("front") || select.label.includes("Front") || select.label.includes("anteriore") || select.label.includes('integrata') );
          },
          openCamera(){
               console.log('openCamera')
               this.isCameraOpen = true
               this.onStart()
               this.$gtag.event("camera-open",{
                    'value': this.mask
               })
          },
          retry(){
               location.reload();
          },
          onCapture() {
               
               
               this.imgKey = 'key-'+Math.random();

               loadImage(this.$refs.webcam.capture(), function (img,data) {
                    console.log('Original ', data) 
                    console.log('Original Exif: ', data.exif) // requires exif extension
                    console.log('Original image width: ', data.originalWidth)
                    console.log('Original image height: ', data.originalHeight)
                    console.log('Original aspectRatio: ', data.aspectRatio)
                    console.log('Original calcAspectRatio: ', (data.originalHeight/data.originalWidth))
                    console.log('Original orientation: ', data.orientation)
               },
                    { orientation: 1, meta: true }
               );

               this.img = this.$refs.webcam.capture();
               var capture = this.$refs.capture
               this.$refs.capture.width = this.width;
               this.$refs.capture.height = this.width*this.streamCameraRatio;
               console.log('capture', capture)
               console.log("onCapture")

               var self = this
               this.$nextTick().then(function () {
                    self.loadCanvas()
               })

               this.$gtag.event("capture-image",{
                    'value': this.mask
               })
               
          },
          loadCanvas() {
               console.log("loadCanvas!")
               var canvas = this.$refs.canvas
               var capture = this.$refs.capture;
               var mask = this.$refs.mask
               
               const context = canvas.getContext('2d');

               canvas.width = this.width;
               canvas.height = this.height;

               console.log('canvas',canvas.width+'x'+canvas.height)
               console.log('capture',capture.width+'x'+capture.height)
               console.log('mask',mask.width+'x'+mask.height)

               capture.addEventListener('load', e => {
                    console.log('isload ',e)
                    context.clearRect(0,0,canvas.width,canvas.height);
                                      

                    this.positionH = (canvas.height-capture.height)/2   

                    if(this.isFrontCamera){
                         context.translate(canvas.width, 0);
                         context.scale(-1, 1);  
                    }

                    console.log('positionH',this.positionH)
                    
                    context.drawImage(capture, 0, this.positionH, canvas.width, canvas.width*this.streamCameraRatio);
                    
                    if(this.isFrontCamera){
                         context.translate(canvas.width, 0);
                         context.scale(-1, 1);  
                    } 
                                
                    context.drawImage(mask, 0, 0, canvas.width, canvas.height);
                    context.restore();
               });

               canvas.toDataURL('image/png', 1.0).replace("image/png", "image/octet-stream");                   
               this.isPhotoTaken = true

          },
          onStarted(stream) {
               console.log("On Started Event", stream);
               let {width, height} = stream.getTracks()[0].getSettings();
               console.log({width, height})
               this.streamCameraWidth = width;
               this.streamCameraHeight = height;
               this.streamCameraRatio = height/width;

               if(this.browserHasErrImg){
                    this.streamCameraRatio = width/height;
               }

               console.log("this.streamCameraRatio",this.streamCameraRatio)

               var cameraboxW = this.$refs.camerabox.clientWidth
               var cameraboxH = this.$refs.camerabox.clientHeight
               console.log({cameraboxW, cameraboxH})

               var maxW = (cameraboxW < this.streamCameraWidth) ? cameraboxW : this.streamCameraWidth;
               this.width = maxW;
          
               this.height = Math.round(maxW*this.ratio);


          },
          onStopped(stream) {
               console.log("On Stopped Event", stream);
          },
          onStop() {
               console.log("STOP STREAM")
               this.$refs.webcam.stop();
          },
          onStart() {
               console.log("START STREAM")
               this.$refs.webcam.start();
          },
          onError(error) {
               console.log("On Error Event", error);
               this.errors = error
          },
          onCameras(cameras) {
               this.devices = cameras;
               console.log("On Cameras Event", cameras);
          },
          onCameraChange(deviceId) {
               this.deviceId = deviceId;
               this.camera = deviceId;
               console.log("On Camera Change Event", deviceId);
          },
          downloadImage() {

               this.saveOnServer();
               
               this.$gtag.event("download-image",{
                    'value': this.mask
               });

          },
          async shareCanvas() {
               const canvasElement = this.$refs.shareImg;
               const dataUrl = canvasElement.toDataURL();
               const blob = await (await fetch(dataUrl)).blob();
               const filesArray = [
               new File(
                    [blob],
                    'animation.png',
                    {
                    type: blob.type,
                    lastModified: new Date().getTime()
                    }
               )
               ];
               const shareData = {
               files: filesArray,
               text: process.env.VUE_APP_BASE_URL,
               title: 'Auguri!'
               };

               if (navigator.canShare && navigator.canShare(shareData)) {
                    navigator.share(shareData);
               } else {
                    this.downloadImage()
               }
          },
          async saveOnServer(){

               var canvas = this.$refs.canvas
               var dataURL = canvas.toDataURL('image/png', 1.0).replace("image/png", "image/octet-stream");
               var d  = moment();
               var datestring = d.format('YYYY-MM-DD-HH-ss')
               var n  = Math.floor(Math.random() * 9999) + 1000;
               this.filename = datestring+'_'+n+'-'+this.mask+'.jpg'
               var data =  { 
                         imgBase64: dataURL,
                         title: this.filename,
                    }               
               this.loading = true;

               var link = document.createElement('a')
                         link.download = this.filename
                         link.target = '_blank';
                         link.href = dataURL;
                         link.click();
                         link.remove();

               
               
               if(this.errDownload){
                    axios.post(process.env.VUE_APP_API_URL+'/save-and-download-image', data).then((resp)=>{
                         console.log(resp)
                         this.loading = false;
                         this.successDownload = resp.data.file;
                    })
               }else{
                    this.loading = false;
                    this.successDownload = dataURL;
               }
          },
          copyText(text) {
              this.browserView = true
              navigator.clipboard.writeText(text);
          }
     }
}
</script>


<style scoped>
.front-camera video {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
   image-orientation: from-image;
}
.front-camera .capture{
     -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.camera-box{
  line-height: 0px;
  font-size: 0px;
  font-display: none;
}
.mask{
     opacity: 0.8;
     position: relative;
     width: 100%;
     height: auto;
}
</style>